import React from "react"

const Stage = () => (
  <section className="promotion">
    <div className="image">
      <div className="promotion-heading">
        <h3>Frühling</h3>
        <h2>bei Kremp</h2>
      </div>
    </div>
    <div className="content clearfix">
      <blockquote>
        Das Schöne am Frühling ist, dass er immer dann kommt,
        <br />
        wenn man ihn am meisten braucht.
        <small className="author">von Jean Paul</small>
      </blockquote>
    </div>
  </section>
)

export default Stage
